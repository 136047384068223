import React, { useCallback, useState, MouseEvent } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  Connection,
  CoordinateExtent,
  Position,
  useNodesState,
  useEdgesState,
  Background,
  Edge,
  Node,
} from 'react-flow-renderer';
import dagre from 'dagre';
import initialItems from './initial-elements';
import FlagsPanel from './FlagsPanel';
import { Accordion, ListGroup } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './layouting.css';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import { decodedTextSpanIntersectsWith } from 'typescript';


//Styling
// https://react-bootstrap.github.io/components/accordion/
interface IFlag {
  flag_type: string
  description: string
}
let flagsData: IFlag[] = [
  {
    flag_type: 'test',
    description: 'something'
  }
];
const onEdgeClick = (_: MouseEvent, edge: Edge) => console.log('click', edge);

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeExtent: CoordinateExtent = [
  [0, 0],
  [1000, 1000],
];

let tenantResponse = {};
let tenantResponseString = "";
let tenantResponseArray: string[] = [''];
const getTenantFlags = () => {
  var xhttp = new XMLHttpRequest();
  var url = 'http://localhost:3090/tenant';
  xhttp.open("GET", url);
  xhttp.setRequestHeader('Content-type', 'application/json')
  xhttp.setRequestHeader('Access-Control-Allow-Headers', '*');
  xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhttp.send();

  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      console.log(JSON.parse(this.response))
      if (tenantResponse == null) {
        // tenantResponse = this.response;
        var tenantResponse = JSON.parse(this.response);
        // console.log(`successfully retrieved tenant data`);
        for(var i = 0; i < tenantResponse.analysis.length; i++) {
          tenantResponseString += tenantResponse.analysis[i].description;
          tenantResponseArray.push(tenantResponse.analysis[i].description);
        }
        // console.log(`tenant response array is: ${tenantResponseArray}`);
      }
    }
  };
}

const LayoutFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialItems.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialItems.edges);
  const [tenantName, setTenantName] = useState("");

  const [selectedNode, setSelectedNode] = useState(initialItems.nodes[0]);
  const [selectedNodeFlags, setSelectedNodeFlags] = useState(flagsData);

  const onNodeClick = (_: MouseEvent, node: Node) => {

    setSelectedNode(node);    
    setSelectedNodeFlags(selectedNode.data.flags)
    console.log(`selected node: ${node.id}`);
  }

  const onConnect = useCallback((connection: Connection) => {
    setEdges((eds) => addEdge(connection, eds));
  }, []);

  getTenantFlags();  

  const onLayout = (direction: string) => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: 150, height: 50 });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const testObject = {
      "numberOfClients": 5,
      "numberOfConnections": 4,
      "numberOfAPIs": 3,
      "numberOfUsers": 0,
      "numberOfActiveUsers": 0
    }

    const layoutedNodes = nodes.map((node, index) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? Position.Left : Position.Top;
      node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
      // we need to pass a slightly different position in order to notify react flow about the change
      // @TODO how can we change the position handling so that we dont need this hack?


      // APPLICATIONS 
      if (node.id == '100') {
        if (node.style !== undefined) {
          node.style.height = 180 * testObject.numberOfClients
          node.draggable = false;
          node.selectable = false;
          node.connectable = false;
        }
      }

      // CONNECTIONS 
      if (node.id == '200') {
        if (node.style !== undefined) {
          node.style.height = 180 * testObject.numberOfConnections
          node.draggable = false;
          node.selectable = false;
          node.connectable = false;
        }
      }

      // APIs 
      if (node.id == '300') {
        if (node.style !== undefined) {
          node.style.height = 180 * testObject.numberOfAPIs
          node.draggable = false;
          node.selectable = false;
          node.connectable = false;
        }
      }


      if(node.data.flags && node.data.flags.length > 0 && node.style !== undefined) {
        var flags = node.data.flags
        for(var i = 0; i < flags.length; i++) {
          if(flags[i].flag_type == "RED") {
            node.style.backgroundColor = '#FE4A49'
            break;
          } else {
            node.style.backgroundColor = '#F18805'
          }
        }
      }

      return node;
    });

    setNodes(layoutedNodes);
  };

  const handleTenantName = () => {
    var tenantName = (document.getElementById('tenantNameInput') as HTMLInputElement).value
    setTenantName((document.getElementById('tenantNameInput') as HTMLInputElement).value)
  }

  if (tenantName === "") {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="p-2">
          <h1 className='space_grotesk'>Craft a Tenants Architecture: </h1>
        </div>
        <div className="p-2">
          <input type="text" id="tenantNameInput" className="form-control" placeholder="Tenant" />
        </div>
        <div className="p-2">
          <button onClick={handleTenantName} className="btn" style={{ backgroundColor: "#eb5424", color: "white" }}>Submit</button>
        </div>
      </div>
    )
  } else {
    var flags_array = (selectedNode.data.flags !== undefined ? selectedNode.data.flags : [])
    var flags_string = "";
    var orange_flags_string = "";
    var red_flags_array: string[] = [];
    var orange_flags_array: string[] = [];
    for(var i = 0; i < flags_array.length; i++) {
      if(flags_array[i].flag_type === 'RED'){
        flags_string += flags_array[i].description + '\n'
        red_flags_array.push(flags_array[i].description);
      }
      else {
        orange_flags_string += flags_array[i].description + '\n'
        orange_flags_array.push(flags_array[i].description);
      }
    }
    if(flags_string === "" || red_flags_array.length == 0){
     flags_string = "No errors detected";
     red_flags_array = ["No errors detected"];
    }

     if(orange_flags_string === "" || orange_flags_array.length == 0){
     orange_flags_string = "No errors detected";
     orange_flags_array = ["No errors detected"];
     }

    return (
      <div className="layoutflow">
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onConnect={onConnect}
            nodeExtent={nodeExtent}
            onInit={() => onLayout('LR')}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            style={{ background: '#3a3a3a', width: '80%', float: 'left' }}
          >
            <Controls />
            <Background color="#aaa" gap={25} />
          </ReactFlow>
          {/* <FlagsPanel/> */}
          <div style={{ background: 'black', width: '20%', height: '100%', float: 'right', color: 'white' }}>
          <hr/>
          <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Welcome to Craft0</h3>
            <hr/><br />
            
            <h6><b>Current Selection:</b> {selectedNode.data.label}</h6>
            
            <br />

            <Accordion alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Critical Errors</Accordion.Header>
                <Accordion.Body style={{ color: 'black' }}>
                {red_flags_array.map(data => (
                <ListGroup.Item>{data}</ListGroup.Item>
                ))}
                </Accordion.Body>
              </Accordion.Item>
              <br></br>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Warnings</Accordion.Header>
                <Accordion.Body style={{ color: 'black' }}>
                {/* <ListGroup.Item>{orange_flags_string}</ListGroup.Item> */}
                {orange_flags_array.map(data => (
                <ListGroup.Item>{data}</ListGroup.Item>
                ))}
                </Accordion.Body>
              </Accordion.Item>
              <br></br>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Tenant-Level Errors/Warnings</Accordion.Header>
                <Accordion.Body style={{ color: 'black' }}>              
                {tenantResponseArray.map(data => (
                <ListGroup.Item>{data}</ListGroup.Item>
                ))}

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </ReactFlowProvider>
      </div>
    );
  }
};

export default LayoutFlow;
