import { Node, Edge, XYPosition, MarkerType } from 'react-flow-renderer';

const position: XYPosition = { x: 0, y: 0 };

const CLIENT_PARENT_NODE_ID = '100';
const CONENCTION_PARENT_NODE_ID = '200';
const RESOURCE_SERVER_PARENT_NODE_ID = '300';

const nodes: Node[] = [
  {
    id: '300',
    data: { label: 'APIs' },
    position: { x: 100, y: 150 },
    className: 'light',
    style: { backgroundColor: 'rgba(24, 22, 22, 0.6)', color: '#6d6c7f', fontSize: 'larger', accentColor: 'white', fontWeight: 'bold', width: 300}, //height: 600 
  },
  {
    id: CLIENT_PARENT_NODE_ID,
    data: { label: 'Applications' },
    position: { x: 425, y: 150 },
    className: 'light',
    style: { backgroundColor: 'rgba(24, 22, 22, 0.6)', color: '#6d6c7f', fontSize: 'larger', accentColor: 'white', fontWeight: 'bold', width: 300}, //height: 600 
  },
  {
    id: CONENCTION_PARENT_NODE_ID,
    data: { label: 'Connections' },
    position: { x: 750, y: 150 },
    className: 'light',
    style: { backgroundColor: 'rgba(24, 22, 22, 0.6)', color: '#6d6c7f', fontSize: 'larger', accentColor: 'white', fontWeight: 'bold', width: 300}, //height: 600 
  },

];

const edges: Edge[] = [];

interface IGraph {
  resourceServers: IResourceServer[]
  clients: IClient[]
  connections: IConnection[]
  edges: IEdge[]
  stats: IStats
  clientGrantEdges: IEdge[]
}

interface IResourceServer {
  id: string
  identifier: string
  name: string
  analysis: IAnalysis[]
}

interface IEdge {
  source: string,
  target: string,
  metadata: IEdgeMetadata
}

interface IEdgeMetadata {
  selected: boolean
  canvas: string
  flow: string
}

interface IClient {
  id: string
  client_id: string
  name: string
  metadata: object
  analysis: IAnalysis[]
}

interface IConnection {
  id: string
  name: string
  strategy: string
  analysis: IAnalysis
}

interface IAnalysis {
  flag_type: string
  description: string
}

interface IStats {
  numberOfClients: number,
  numberOfConnections: number,
  numberOfAPIs: number,
  numberOfUsers: number,
  numberOfActiveUsers: number
}

let graphData: IGraph
let clients: IClient[]
let connections: IConnection[]

const getClients = () => {
  var xhttp = new XMLHttpRequest();
  var url = 'http://localhost:3090/clients';
  xhttp.open("GET", url);
  xhttp.setRequestHeader('Content-type', 'application/json')
  xhttp.setRequestHeader('Access-Control-Allow-Headers', '*');
  xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhttp.send();

  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      console.log(JSON.parse(this.response))
      if (clients == null) {
        clients = this.response;
        var jsonResponse = JSON.parse(this.response);
        console.log(`successfully retrieved clients`);
        clients = <IClient[]>jsonResponse;
        console.log(`test clientList data is: ${clients}`);
        console.log(`test clientList item-1 is: ${clients[0].id}`);
        drawClientNodes();
      }
    }
  };
}

function drawClientNodes() {
  graphData.clients.forEach((clientData: IClient, index) => {
    const clientNode: Node = {
      id: '',
      position: { x: 500, y:(index * 100) + 250 },
      // position: { x: 175, y: (index * 200) + 250 },
      data: undefined,
      // parentNode: CLIENT_PARENT_NODE_ID,
      zIndex: 10,
      extent: 'parent',
      draggable: true,
      style: {backgroundColor:'#08A045', fontFamily: 'SpaceGrotesk'},
      connectable: false
    };
    // console.log(`mapping client data to nodes, ${clientData.id}`);
    clientNode.id = clientData.id;
    clientNode.data = {
      label: clientData.name,
      flags: clientData.analysis
    }
    nodes.push(clientNode);
  });
}

const getConnections = () => {
  var xhttp = new XMLHttpRequest();
  var url = 'http://localhost:3090/connections';
  xhttp.open("GET", url);
  xhttp.setRequestHeader('Content-type', 'application/json')
  xhttp.setRequestHeader('Access-Control-Allow-Headers', '*');
  xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhttp.send();

  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      console.log(`successfully retrieved connections`);
      if (connections == null) {
        var jsonResponse = JSON.parse(this.response);
        connections = <IConnection[]>jsonResponse;
        // console.log(`test connectionList data is: ${connections}`);
        console.log(`test connectionList item-1 is: ${connections[0].id}`);
        drawConnectionNodes();
      }
    }
  };
}


function drawConnectionNodes() {
  graphData.connections.forEach((connectionData: IConnection, index) => {
    const connectionNode: Node = {
      id: '',
      position: { x: 825, y: (index * 158) + 250  },
      // position: { x: 900, y: (index * 200) + 350 },
      data: undefined,
      // parentNode: CONENCTION_PARENT_NODE_ID,
      zIndex: 10,
      extent: 'parent',
      draggable: true,
      style: {backgroundColor:'#08A045', fontFamily: 'SpaceGrotesk'},
      connectable: false
    };
    // console.log(`mapping client data to nodes, ${clientData.id}`);
    connectionNode.id = connectionData.id;
    connectionNode.data = {
      label: connectionData.name,
      flags: connectionData.analysis
    }
    nodes.push(connectionNode);
    
  });
}

function drawResourceServers() {
  graphData.resourceServers.forEach((resourceServerData: IResourceServer, index) => {
    const clientNode: Node = {
      id: '',
      position: { x: 75, y: (index * 90) + 100  },
      data: undefined,
      parentNode: RESOURCE_SERVER_PARENT_NODE_ID,
      zIndex: 10,
      extent: 'parent',
      draggable: true,
      style: {backgroundColor:'#08A045', fontFamily: 'SpaceGrotesk'},
      connectable: false
    };
    // console.log(`mapping client data to nodes, ${clientData.id}`);
    clientNode.id = resourceServerData.identifier;
    clientNode.data = {
      label: resourceServerData.name,
      flags: resourceServerData.analysis
    }
    nodes.push(clientNode);
  });
}


function drawEdges() {
  graphData.edges.forEach((edgeData: IEdge) => {
    const clientConnectionEdge: Edge = {
      id: '',
      source: '',
      target: '',
      type: 'default',
      animated: true,
      zIndex: 1,
      markerEnd: {
        type: MarkerType.ArrowClosed
      },
    };
    // console.log(`mapping client data to nodes, ${clientData.id}`);
    clientConnectionEdge.id = `e_${edgeData.source}_${edgeData.target}}`
    clientConnectionEdge.source = edgeData.target
    clientConnectionEdge.target = edgeData.source

    //TODO: temporary hard-coding for demo
    if(edgeData.target === 'xDHKp30BYuwOEdxl2CRJ3MpBMQkuyIQ1' && edgeData.source === 'con_AG3peDTD5JMk0C9I'){
      clientConnectionEdge.label = 'ROPG'
    }
    else{
      clientConnectionEdge.label = edgeData.metadata.flow
    }
    edges.push(clientConnectionEdge);
  });
}

function drawAppApiEdges() {
  console.log(`client grant edges: ${graphData.clientGrantEdges.toString()}`);
  graphData.clientGrantEdges.forEach((edgeData: IEdge) => {
    const appApiEdge: Edge = {
      id: '',
      source: '',
      target: '',
      type: 'default',
      animated: false,
      zIndex: 1,
      markerEnd: {
        type: MarkerType.ArrowClosed
      },
      style: {width: '200px', color:'red'}
    };
    // console.log(`mapping client data to nodes, ${clientData.id}`);
    appApiEdge.id = `e_${edgeData.source}_${edgeData.target}}`
    appApiEdge.source = edgeData.target
    appApiEdge.target = edgeData.source    
    edges.push(appApiEdge);
  });
}

const getGraph = () => {
  var xhttp = new XMLHttpRequest();
  var url = 'http://localhost:3090/graph';
  xhttp.open("GET", url);
  xhttp.setRequestHeader('Content-type', 'application/json')
  xhttp.setRequestHeader('Access-Control-Allow-Headers', '*');
  xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhttp.send();

  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      console.log(JSON.parse(this.response))
      if (clients == null) {
        clients = this.response;
        var jsonResponse = JSON.parse(this.response);
        console.log(`successfully retrieved graph data`);
        graphData = <IGraph>jsonResponse;
        console.log(`test graph data is: ${graphData}`);
        console.log(`test graph client-1 is: ${graphData.clients[0].id}`);
        console.log(`test graph resourceserver-1 is: ${graphData.resourceServers[0].id}`);
        console.log(`test graph connection-1 is: ${graphData.connections[0].id}`);
        console.log(`test graph connection-1 is: ${graphData.connections[0].id}`);
        console.log(`test graph edge-1 is: ${graphData.edges[0].source}`);
        console.log(`stats no of clients: ${graphData.stats.numberOfClients}`);
        drawClientNodes();
        drawConnectionNodes();
        drawResourceServers();
        drawEdges();
        drawAppApiEdges();
      }
    }
  };
}

getGraph();

const nodesAndEdges = { nodes, edges };

export default nodesAndEdges;
